<div class="container-fluid bG bGimg stay">
    <div class="row">
        <div class="col-1 noPadding d-none d-lg-block stay">
            <app-navbar></app-navbar>
        </div>
        <div class="col-1 noPadding d-xs-block d-lg-none stay">
            <app-mobilenav></app-mobilenav>
        </div>
    </div>
    <form class="content stay" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
        <div class="container">
            <div class="row align-items-stretch justify-content-center no-gutters">
                <div class="col-10">
                    <div class="form h-100 contact-wrap p-5">
                        <h3 class="mb-2 title">Let's Chat Sometime!</h3>
                        <p class="mb-4 subText">If you're interested in collaborating, let's get connected!</p>
                        <div class="row">
                            <div class="col-md-6 form-group mb-3">
                                <label for="name" class="col-form-label">Name *</label>
                                <input type="text" class="form-control" [formControl]="name" placeholder="Your name"
                                    required>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-error class="error" *ngIf="name.invalid && name.touched">Please enter your
                                            name</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group mb-3">
                                <label for="email" class="col-form-label">Email *</label>
                                <input type="email" class="form-control" [formControl]="email"
                                    placeholder="email@example.com" required>
                                <div class="row">
                                    <div class="col-12">
                                        <small class="error" *ngIf="email.invalid && email.touched">Please enter a valid
                                            email</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-12 form-group mb-3">
                                <label for="message" class="col-form-label">Message *</label>
                                <textarea class="form-control" [formControl]="message" maxlength="256"
                                    placeholder="I'm interested in..."></textarea>
                                <div class="row">
                                    <div class="col-6">
                                        <small class="error" *ngIf="message.invalid && message.touched">Please enter a
                                            message</small>
                                    </div>
                                    <div class="col-6">
                                        <small class="d-flex justify-content-end subText2"> {{message.value?.length ||
                                            0}}/500</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-md-8 col-lg-6">
                                <button [disabled]="isLoading" type="submit" class="btn btn1 py-2 px-4"> Send
                                    Message</button>
                                <input [formControl]="honeypot" class="hidden" type="text" />
                            </div>
                            <div class="col-12 mt-5 text-center">
                                <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                                    <span class="mt-5 subText text-center">{{responseMessage}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>