<div class="container-fluid bG bGimg pb-5 stay">
    <div class="row">
        <div class="col-1 noPadding d-none d-md-block stay">
            <app-navbar></app-navbar>
        </div>
        <div class="col-1 noPadding d-xs-block d-md-none stay">
            <app-mobilenav></app-mobilenav>
        </div>
        <div class="col-11 content stay ">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 order-1">
                        <div class="row">
                            <div class="px-4 col-12 col-sm-8 ">
                                <h1 class="px-4 mt-5">I am a <span class="emphasis">{{identity ? identity : 'Web
                                        Developer'}}!</span></h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 mt-3 noPadding order-2">
                        <p class="text-left px-5">

                            I attended the University of California, Santa Cruz, where I earned a <span
                                class="emphasis">Bachelors degree in Biology</span>.

                            Afterwards, I began my career in the Agriculture industry, working in plant and seed
                            pathology.

                            Advancements in my career brought me from being a laboratory technician, to eventually
                            supervising seed pathology laboratories for a couple of different companies.
                        </p>
                        <p class="text-left px-5">
                            When the pandemic began in early 2020, I saw an opportunity to make a career shift. Although
                            I was finding much success
                            in the agriculture industry, I really wanted to pursue a career in <span
                                class="emphasis">Web Development</span>.
                        </p>
                        <p class="text-left px-5">
                            There are many challenges that the
                            tech industry faces, and I wanted to be a part of a work force that can help address those
                            many problems. So I decided to begin learning to program in September of 2020.
                        </p>
                        <p class="text-left px-5">
                            I joined <span class="emphasis">CodeStack Academy</span>, and went through
                            1,000+ hours of instructor
                            lead coursework, covering the current technology utilized by Web Developers today. CodeStack
                            Academy also included an <span class="emphasis">8 week internship</span>, which has helped
                            reinforce the skills
                            needed to succeed in the tech industry. I believe I have the tools
                            necessary to continue evolving as
                            Web Developer, and my my goal for this portfolio is to showcase just that.
                        </p>
                        <div class="row">
                            <div class="col-12 center mt-3">
                                <a title="Click to download Carlos' Resume!" (click)="downloadFile()"
                                    class="btn btn1 px-4 py-2 index">Download My
                                    Resume</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 d-none d-xl-block center relative noPadding order-3" (mouseenter)="changeImg=true"
                        (mouseleave)="changeImg=false">
                        <img *ngIf="!changeImg" class="absolute images padding" src="./assets/KawaiiAF.png"
                            alt="Headshot of Carlos">
                        <img *ngIf="changeImg" class="absolute images2" src="./assets/saxy.png"
                            alt="Carlos with a saxophone">
                    </div>
                    <div class="col-md-6 col-xl-4 noPadding paddingTop shiftUp order-4">
                        <div class="row">
                            <div class="col-12 center borderBottom padding">
                                <div class="row">
                                    <div class="col-3 d-sm-none"></div>
                                    <div class="col-6 col-sm-12 center">
                                        <h3 class="mt-3 text-center">Languages & Frameworks</h3>
                                    </div>
                                    <div class="col-3 d-sm-none"></div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_html5.svg" alt="HTML 5 Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>HTML</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_css3.svg" alt="CSS 3 Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>CSS</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_javascript.svg" alt="JavaScript Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>JavaScript</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_typescript.svg" alt="TypeScript Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>TypeScript</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_csharp.svg" alt="C# Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>C#</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_bootstrap.svg" alt="Bootstrap Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Bootstrap</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_angular.svg" alt="Angular Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Angular 11+</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/dev_ionic.svg" alt="Ionic Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Ionic</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-12 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/sqllogowhitesmol.png" alt="SQL Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>SQL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 center padding">
                                <div class="row">
                                    <div class="col-3 d-sm-none"></div>
                                    <div class="col-6 col-sm-12 center">
                                        <h3 class="mt-3 text-center">Development Tools</h3>
                                    </div>
                                    <div class="col-3 d-sm-none"></div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img class="imgWidth" src="./assets/icons/vscode.png"
                                                    alt="VS Code Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>VS Code</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img class="imgWidth" src="./assets/icons/git.png" alt="Git Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Git</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <i class="icon fab fa-github fa-3x"></i>
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>GitHub</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <i class="icon fab fa-unity fa-3x"></i>
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Unity</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/filezillalogo.png" alt="FileZilla Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>FileZilla</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/postmanlogo.png" alt="Postman Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Postman</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img class="imgWidth" src="./assets/icons/adobexd.png"
                                                    alt="Adobe XD Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Adobe XD</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/jiralogo.png" alt="Jira Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Jira</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 col-md-12 col-lg-4 mt-4 center noPadding">
                                        <div class="row">
                                            <div class="col-12 center noPadding">
                                                <img src="./assets/icons/slackicon50.png" alt="Slack Icon">
                                            </div>
                                            <div class="col-12 center noPadding">
                                                <p>Slack</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>