<div class="containter bG bGimg stay">
    <div class="row position">
        <div class="col-12">
            <h1>Carlos Gonzalez</h1>
        </div>
    </div>
    <div class="row position2">
        <div class="col-12">
            <h2>Web Developer</h2>
        </div>
    </div>
    <div class="row position2 mt-2">
        <div class="col-12">
            <p>I am a Web Developer based out of Stockton, CA.
                <br>
                Check out <span class="highlight" routerLink="/work">my work!</span>
            </p>
        </div>
    </div>
</div>