<div class="borderR bodyBG sidebar">
    <div class="container-fluid bG noPadding">
        <div class="row">
            <div class="col-12 marginTop center">
                <img class="logo" style="width:80%" src="./assets/CG_logo.svg">
            </div>
        </div>
        <div class="row borderT pt-2 btn btn1">
            <div class="col-12">
                <p routerLink="/main" class="center noPadding">Home</p>
            </div>
        </div>
        <div class="row borderT pt-2 btn btn1">
            <div class="col-12">
                <p routerLink="/about" class="center noPadding">About</p>
            </div>
        </div>
        <div class="row borderT pt-2 btn btn1">
            <div class="col-12">
                <p routerLink="/work" class="center noPadding">Work</p>
            </div>
        </div>
        <div class="row borderT borderB pt-2 btn btn1">
            <div class="col-12">
                <p routerLink="/contact" class="center noPadding">Contact</p>
            </div>
        </div>
        <div class="row pt-5">
            <div class="col-4 center">
                <a title="Check out Carlos' Linkedin in a seperate page!"
                    href="https://www.linkedin.com/in/cgonzalez1993/" target="_blank"
                    class="icon fab fa-linkedin fa-2x ml-3"></a>
            </div>
            <div class="col-4 center">
                <a title="Check out Carlos' code repositories in a seperate page!"
                    href="https://github.com/cgonzalez1993" target="_blank" class="icon fab fa-github-square fa-2x"></a>
            </div>
            <div class="col-4 center codewars">
                <a title="Check out Carlos' Codewars profile in a seperate page!"
                    href="https://www.codewars.com/users/cgonzalez1993" target="_blank">
                    <img href="https://www.codewars.com/users/cgonzalez1993" target="_blank" class="codewars img-top"
                        src="./assets/codewars-blue.png" alt="Codewars Icon">
                </a>
                <img title="Check out Carlos' Codewars profile in a seperate page!"
                    href="https://www.codewars.com/users/cgonzalez1993" target="_blank" class="codewars"
                    src="./assets/codewars-white.png" alt="Codewars Icon">
            </div>
        </div>
    </div>
</div>