<div class="borderR body sidebar">
    <div class="container-fluid bG noPadding">
        <div class="row">
            <div class="col-12 marginTop center ">
                <img class="logo" style="width:90%" src="./assets/CG_logo.svg">
            </div>
        </div>
        <div routerLink="/main" class="row borderT pt-4 pb-4 btn btn1 nB mt-5">
            <div title="Go to the Home Page" class="col-12">
                <i class="fas fa-home icon2 fa-2x"></i>
            </div>
        </div>
        <div routerLink="/about" class="row borderT pt-4 pb-4 btn btn1 ">
            <div title="Go to Carlos' About Page" class="col-12">
                <i class="fas fa-address-card icon2 fa-2x"></i>
            </div>
        </div>
        <div routerLink="/work" class="row borderT pt-4 pb-4 btn btn1">
            <div title="Go to Carlos' Work Page" class="col-12">
                <i class="fas fa-code-branch icon2 fa-2x"></i>
            </div>
        </div>
        <div routerLink="/contact" class="row borderT pt-4 pb-4 btn btn1">
            <div title="Go to contact Carlos" class="col-12">
                <i class="far fa-envelope icon2 fa-2x"></i>
            </div>
        </div>
        <div routerLink="/contact" class="row borderT pt-4 pb-4 btn btn1">
            <div class="col-12">
            <a title="Check out Carlos' Linkedin in a seperate page!" href="https://www.linkedin.com/in/cgonzalez1993/"
                target="_blank" class="icon fab fa-linkedin fa-2x"></a>
            </div>
        </div>
        <div routerLink="/contact" class="row borderT pt-4 pb-4 btn btn1">
            <div class="col-12">
                <a title="Check out Carlos' code repositories in a seperate page!" href="https://github.com/cgonzalez1993"
                    target="_blank" class="icon fab fa-github-square fa-2x"></a>
            </div>
        </div>
        <div routerLink="/contact" class="row borderT borderB pt-4 pb-4 btn btn1">
            <div class="col-12 codewars">
                <a title="Check out Carlos' Codewars profile in a seperate page!" href="https://www.codewars.com/users/cgonzalez1993"
                    target="_blank">
                    <img title="Check out Carlos' Codewars profile in a seperate page!" href="https://www.codewars.com/users/cgonzalez1993"
                        target="_blank" class="codewars" src="./assets/codewars-white.png" alt="Codewars Icon">
                </a>
            </div>
        </div>

        <!-- <div class="stick">
            <div class="row pt-3 pb-4">
                <div class="col-12 center">
                    <a title="Check out Carlos' Linkedin in a seperate page!"
                        href="https://www.linkedin.com/in/cgonzalez1993/" target="_blank"
                        class="icon fab fa-linkedin fa-2x"></a>
                </div>
            </div>
            <div class="row pt-3 pb-4">
                <div class="col-12 center">
                    <a title="Check out Carlos' code repositories in a seperate page!"
                        href="https://github.com/cgonzalez1993" target="_blank"
                        class="icon fab fa-github-square fa-2x"></a>
                </div>
            </div>
            <div class="row pt-3 pb-4">
                <div class="col-12 center codewars">
                    <a title="Check out Carlos' Codewars profile in a seperate page!"
                        href="https://www.codewars.com/users/cgonzalez1993" target="_blank">
                        <img href="https://www.codewars.com/users/cgonzalez1993" target="_blank"
                            class="codewars img-top" src="./assets/codewars-blue.png" alt="Codewars Icon">
                    </a>
                    <img title="Check out Carlos' Codewars profile in a seperate page!"
                        href="https://www.codewars.com/users/cgonzalez1993" target="_blank" class="codewars"
                        src="./assets/codewars-white.png" alt="Codewars Icon">
                </div>
            </div>
        </div> -->
    </div>
</div>