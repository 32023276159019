<div class="container-fluid bG bGimg pb-5">
    <div class="row">
        <div class="col-1 noPadding d-none d-lg-block stay">
            <app-navbar></app-navbar>
        </div>
        <div class="col-1 noPadding d-xs-block d-lg-none stay">
            <app-mobilenav></app-mobilenav>
        </div>
        <div class="content stay">
            <div class="row mt-5 ">
                <div class="col-12 mb-5">
                    <h1 class="center title">My Work</h1>
                </div>
            </div>
            <div class="row d-flex justify-content-around">
                <!-- Work Cards -->
                <div *ngFor="let work of workList, index as i" class="center m-3">
                    <div class="image-flip">
                        <div class="mainflip">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-center {{work.Color}}">
                                        <img style="{{work.Style}}" src="{{work.Image}}" alt="Project Image">
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-center paddingText cblue">
                                        <div class="row">
                                            <div class="col-12 noPadding">
                                                <h3>{{work.ProjectName}}</h3>
                                            </div>
                                            <div class="col-12 noPadding paddingDescription">
                                                <p>
                                                    {{work.ProjectDescription}}
                                                </p>
                                            </div>
                                            <div class="col-12 paddingIcons" *ngIf="work.Repository!='' && work.WebUrl!=''">
                                                <div class="row">
                                                    <div class="col-2 noPadding"></div>
                                                    <div class="col-4 center borderRight">
                                                        <a class="fab fa-github fa-2x icon" href="{{work.Repository}}" title="Open the repository for this project in a new webpage." target=”_blank”></a>
                                                    </div>
                                                    <div class="col-4 center">
                                                        <a class="fas fa-globe fa-2x icon" href="{{work.WebUrl}}" title="Open the application for this project in a new webpage." target=”_blank”></a>
                                                    </div>
                                                    <div class="col-2 noPadding"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>