<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="chromeTab d-flex justify-content-end">
                <div class="d-flex flex-row align-items-center">
                    <div>
                        <i class="far fa-window-minimize fa-xs indent indent2"></i>
                    </div>
                    <div>
                        <i class="far fa-window-restore fa-xs indent"></i>
                    </div>
                    <div>
                        <i class="fas fa-times fa-xs indent"></i>
                    </div>
                </div>
            </div>

            <ngb-carousel class="chromeBody" [showNavigationArrows]="true" [showNavigationIndicators]="false"
                [interval]="4000" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'three'" #carousel>
                <ng-template ngbSlide id="one">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[0]" alt="Image of Deal or no Deal: Naruto Edition">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="two">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[1]" alt="Image of Random Name Generator Project">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="three">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[2]" alt="Image of Hamster Hotel Project">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="four">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[3]" alt="Image of Puppy Restaurant Project">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="five">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[4]" alt="Image of Business Redevelopment Project">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="six">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[5]" alt="Image of Flash Card App">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="seven">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[6]" alt="Image of Weather App">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="eight">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[7]" alt="Image of Rock, Paper, Scissors, Lizard, Spock, App">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="nine">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[8]" alt="Image of To-Do List App">
                    </div>
                </ng-template>
                <ng-template ngbSlide id="ten">
                    <div class="picsum-img-wrapper">
                        <img [src]="images[9]" alt="Image of Fullstack project">
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</div>