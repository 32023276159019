import { Component, OnInit } from '@angular/core';
import { IWorkinfo } from 'src/app/interfaces/workinfo';
import { DataService } from 'src/app/services/Data/data.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  workList: IWorkinfo[] = [];

  constructor(private dService: DataService) { }

  ngOnInit(): void {
    this.workList = this.dService.sendList();
    console.log(this.workList);
  }

}
