import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  ngOnInit(): void {
  }
  images = ['./assets/portfolio/DonD.png', './assets/portfolio/rngProject.png',
    './assets/portfolio/lepetite.png', './assets/portfolio/muzzles.png',
    './assets/portfolio/amor.png', './assets/portfolio/flashcard2.png',
    './assets/portfolio/weather.png', './assets/portfolio/rpsls.png',
    './assets/portfolio/todolist.png', './assets/portfolio/eightand.png',];


}
