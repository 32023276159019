import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  changeImg: boolean = true;
  identity: string = "";


  constructor() {
    this.changeImg = false;
  }

  ngOnInit(): void {
    this.changeIdentity();
  }

  downloadFile() {
    let link = document.createElement("a");
    link.download = "CGonzalez-Resume2021";
    link.href = "./assets/C.Gonzalez.Resume.2021.pdf";
    link.click();
  }


  changeIdentity() {

    setInterval(() => {

      let identityList = ["Web Developer", "Scientist", "Musician", "Father", "Team Player", "Leader"]

      this.identity = identityList[Math.floor(Math.random() * identityList.length)];

    }, 2400);
  }

}
