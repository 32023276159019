import { Injectable } from '@angular/core';
import { IWorkinfo } from 'src/app/interfaces/workinfo';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private work: any;

  private workList: IWorkinfo[] = [
    {
      ProjectName: "Deal or No Deal",
      ProjectDescription: "Inspired by Naruto and developed in Unity, here is my take on Deal or No Deal.",
      Repository: "",
      WebUrl: "",
      Image: "../../../assets/scroll.png",
      Color: "teal",
      Style: "width:100%"
    },
    {
      ProjectName: "Random Name Generator",
      ProjectDescription: "A simple random name generator utilzing local storage.",
      Repository: "https://github.com/cgonzalez1993/RandomNameGenerator",
      WebUrl: "https://rngnamegenerator.azurewebsites.net",
      Image: "../../../assets/rng.png",
      Color: "lightblue",
      Style: "width:75%"
    },
    {
      ProjectName: "Hamster Hotel",
      ProjectDescription: "My first project using HTML and CSS to replicate an Adobe XD prototype assigned to me.",
      Repository: "https://github.com/cgonzalez1993/LePetiteMaison_HamsterHotel",
      WebUrl: "https://lepetitemaison.azurewebsites.net/",
      Image: "../../../assets/hamsterhotel.png",
      Color: "teal",
      Style: "width:75%"
    },
    {
      ProjectName: "Puppy Restaurant",
      ProjectDescription: "First HTML/CSS team project completed utlizing a GitHub repository.",
      Repository: "https://github.com/cgonzalez1993/Puppy-Restaurant",
      WebUrl: "https://codebusterspuppyrestaurant.azurewebsites.net",
      Image: "../../../assets/MuzzlesLogo.png",
      Color: "lightblue",
      Style: "width:100%; margin-top: 10%"
    },
    {
      ProjectName: "Business Redevelopment",
      ProjectDescription: "Worked with a local business, Amor Kombucha, to redesign their website. ",
      Repository: "https://github.com/cgonzalez1993/AmorKombucha",
      WebUrl: "https://amorkombucha.azurewebsites.net",
      Image: "../../../assets/flor.png",
      Color: "teal",
      Style: "width:15%"
    },
    {
      ProjectName: "Flash Card App",
      ProjectDescription: "Created a flash card app, utilizing google sheets to fetch information.",
      Repository: "https://github.com/cgonzalez1993/FlashCardApp",
      WebUrl: "https://codingflashcardapp.azurewebsites.net",
      Image: "../../../assets/flashcards.png",
      Color: "lightblue",
      Style: "width:80%"
    },
    {
      ProjectName: "Weather App",
      ProjectDescription: "Developed a weather app designed by a classmate, utilizing the Open Weather API to pull relevant weather information.",
      Repository: "https://github.com/cgonzalez1993/weatherapp",
      WebUrl: "https://weatherthingy.azurewebsites.net/",
      Image: "../../../assets/weather.png",
      Color: "teal",
      Style: "width:100%"
    },
    {
      ProjectName: "Rock, Paper, Lizard, Scissors, Spock",
      ProjectDescription: "A game of Rock, Paper, Scissor, Lizard, Spock utlizing an API call to play against the CPU.",
      Repository: "https://github.com/cgonzalez1993/RPSLS",
      WebUrl: "https://rpslswebapp.azurewebsites.net/",
      Image: "../../../assets/rpslsImg.png",
      Color: "lightblue",
      Style: "width:100%"
    },
    {
      ProjectName: "To-Do List",
      ProjectDescription: "A handy To-Do List, developed in Angular, utilizing local storage.",
      Repository: "https://github.com/cgonzalez1993/todolist",
      WebUrl: "https://todolist.azurewebsites.net/",
      Image: "../../../assets/todo.png",
      Color: "teal",
      Style: "width:60%"
    },
    {
      ProjectName: "EightAnd",
      ProjectDescription: "An Event Coordinating fullstack application developed with the Ionic Framework, currently in development with J.Racca, A. Ato, K.Martinez.",
      Repository: "",
      WebUrl: "",
      Image: "../../../assets/eightand.png",
      Color: "lightblue",
      Style: "width:55%"
    },
  ];

  sendList(): IWorkinfo[]{
    return this.workList;
  }

  setWork(data: IWorkinfo){
    this.work = data;
  }

  returnWork() {
    return this.work;
  }

}

