<div class="container-fluid bG bGimg">
    <div class="row">
        <div class="col-1 noPadding d-none d-md-block">
            <app-navbar></app-navbar>
        </div>
        <div class="col-1 noPadding d-xs-block d-md-none ">
            <app-mobilenav></app-mobilenav>
        </div>
        <div class="content still">
            <div class="row noPadding">
                <div class="col-xs-12 col-xl-8 noPadding marginTop parent">
                    <app-left class="content"></app-left>
                </div>
                <div class="col-4 d-none d-xl-block noPadding">
                    <app-right></app-right>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-8 col-xl-8 position child">
                    <app-carousel></app-carousel>
                </div>
            </div>
        </div>
    </div>
</div>